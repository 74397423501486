import { PublicPlan } from '@wix/ambassador-pricing-plans-read-api/types';

export function isFree(plan: PublicPlan): boolean {
  return getPriceAmount(plan) === '0';
}

export function getPriceAmount(plan: PublicPlan): string | undefined {
  return plan.paymentOptions?.price?.amount;
}

export function getPrice(plan: PublicPlan): number {
  return parseFloat(getPriceAmount(plan) ?? '');
}

export function isStartDateCustomizable(plan: PublicPlan): boolean {
  return !isFree(plan) && !!plan.buyerCanPickFutureStartDate;
}
