export const PRICING_PLANS_APP_DEF_ID = '1522827f-c56c-a5c9-2ac9-00f9e6ae12d3';
export const NO_BREAK_SPACE = '\u00A0';
export const BOOKINGS_APP_DEF_ID = '13d21c63-b5ec-5912-8397-c3a5ddb27a97';

export const EXPERIMENTS = {
  NEW_MODAL_STYLE: 'specs.membership.NewEditorModal',
};

export const HOOKS = {
  PP_SETTINGS_DESIGN_BAB_APP_BACKGROUND_PICKER: 'app-background-picker',
  PP_SETTINGS_DESIGN_BAB_R_TITLE_BACKGROUND_PICKER: 'title-background-picker',
  PP_SETTINGS_DESIGN_BAB_R_BENEFITS_BACKGROUND_PICKER: 'benefits-background-picker',
  PP_SETTINGS_DESIGN_BAB_R_PLAN_BORDER_WIDTH_SLIDER: 'card-border-width-slider',
  PP_SETTINGS_DESIGN_BAB_R_CARD_BORDER_PICKER: 'card-border-picker',
  PP_SETTINGS_DESIGN_BAB_H_BENEFITS_BACKGROUND_PICKER: 'highlighted-plan-benefits-background-picker',
  PP_SETTINGS_DESIGN_BAB_H_TITLE_BACKGROUND_PICKER: 'highlighted-plan-title-background-picker',
  PP_SETTINGS_DESIGN_TEXT_PAGE_HEADER_PICKER: 'page-header-picker',
  PP_SETTINGS_DESIGN_TEXT_PLAN_TITLE_PICKER: 'plan-title-picker',
  PP_SETTINGS_DESIGN_TEXT_PLAN_PRICE_PICKER: 'plan-price-picker',
  PP_SETTINGS_DESIGN_TEXT_PLAN_TAGLINE_PICKER: 'plan-tagline-picker',
  PP_SETTINGS_DESIGN_TEXT_PLAN_BENEFITS_PICKER: 'plan-benefits-picker',
  PP_SETTINGS_DESIGN_BUTTON_R_STYLE_EMPTY_CORNERED: 'button-style-empty-cornered',
  PP_SETTINGS_DESIGN_BUTTON_R_STYLE_EMPTY_ROUNDED: 'button-style-empty-rounded',
  PP_SETTINGS_DESIGN_BUTTON_R_STYLE_FULL_CORNERED: 'button-style-full-cornered',
  PP_SETTINGS_DESIGN_BUTTON_R_STYLE_FULL_ROUNDED: 'button-style-full-rounded',
  PP_SETTINGS_DESIGN_BUTTON_R_CORNER_RADIUS: 'button-corner-radius',
  PP_SETTINGS_DESIGN_BUTTON_R_BORDER_WIDTH: 'button-border-width',
  PP_SETTINGS_DESIGN_BUTTON_R_BORDER_COLOR: 'button-border-color',
  PP_SETTINGS_LAYOUT_TAB_LINE_SPACING: 'benefits-layout-line-spacing',
  PP_SETTINGS_LAYOUT_TAB_TEXT_ALIGNMENT_LEFT: 'left-alignment',
  PP_SETTINGS_LAYOUT_TAB_TEXT_ALIGNMENT_CENTER: 'center-alignment',
  PP_SETTINGS_LAYOUT_TAB_TEXT_ALIGNMENT_RIGHT: 'right-alignment',
  PP_SETTINGS_TEXT_TAB_PAGE_HEADER: 'page-header-text',
  PP_SETTINGS_TEXT_TAB_BUTTON: 'button-text',
  PP_SETTINGS_TEXT_TAB_RIBBON: 'ribbon-text',
};
