import { PricingPlansReadApi, ListPublicPlansRequest } from '@wix/ambassador-pricing-plans-read-api/http';
import { ApiHeaders } from './headers';

type PricingPlansReadApi = ReturnType<typeof PricingPlansReadApi>;
type PlansService = ReturnType<PricingPlansReadApi['PlansService']>;

export function createPlansApi(headers: ApiHeaders, baseUrl = '') {
  return new PlansApi(headers, PricingPlansReadApi(baseUrl + '/_api/paid-plans').PlansService());
}

export class PlansApi {
  constructor(protected headers: ApiHeaders, protected plansService: PlansService) {}

  public async loadPaidPlans(request: ListPublicPlansRequest = {}) {
    const { plans } = await this.plansService(this.headers).listPublicPlans(request);
    return plans || [];
  }
}
